import React from 'react';

import { Image } from 'semantic-ui-react';

import * as Constants from "../constants";
import Logo from '../img/full-logo-white.png';

export default function Footer() {

    const style = {
        color: Constants.OffWhite,
        fontFamily: "'Playfair Display', serif"
    };

    return (
        <div style={{...Constants.FlexCenter, height: "225px", width: "100%", backgroundColor: Constants.Maroon, color: Constants.OffWhite, flexDirection: "column"}}>
            <h3>Mike's Services, Livonia MI</h3>
            <a href="tel:734-591-0825">734-591-0825</a>
            <a href="mailto:mikeserv7@yahoo.com">mikeserv7@yahoo.com</a>
            <Image src={Logo} style={{height: "65px", width: "75px", paddingTop: "1em"}} />     
        </div>
    );
};
