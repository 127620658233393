import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Constants from '../constants';

import { Grid } from 'semantic-ui-react';

import Header from './Header';
import Home from './Home';
import About from './About';
import Bathroom from './Bathroom';
import Gallery from './Gallery';
import Kitchen from './Kitchen';
import Footer from './Footer';
import Basement from './Basement';

export default function Main() {
    const currentlySelected = useSelector(state => state.global.currentlySelectedSection);

    const components = [
        {
            name: Constants.Home,
            component: <Home />
        },
        {
            name: Constants.About,
            component: <About />
        },
        {
            name: Constants.Bathroom,
            component: <Bathroom />
        },
        {
            name: Constants.Gallery,
            component: <Gallery />
        },
        {
            name: Constants.Kitchen,
            component: <Kitchen />
        },
        {
            name: Constants.Basement,
            component: <Basement />
        }
    ];

    const [selectedComponent, setSelectedComponent] = useState(components[0].component)

    useEffect(() => {
        setSelectedComponent(components.find(x => x.name === currentlySelected).component);
    }, [currentlySelected]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Header />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    {selectedComponent}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Footer />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};