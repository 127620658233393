import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Parallax } from 'react-parallax';

import * as Constants from '../constants';
import BasementPhoto from '../img/basement-stairs.jpg';
import DarkBrick from '../img/dark-brick.jpg';

export default function Basement() {
    const paragraph = {
        ...Constants.Paragraph,
        fontSize: "1.2rem"
    };

    const header = {
        ...Constants.Header
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8} style={{ ...Constants.FlexCenter, minHeight: "40vh" }}>
                    <div style={{ width: "90%" }}>
                        <h1 style={header}>DITCH CLUTTER - ADD LIVING SPACE</h1>
                        <p style={paragraph}>Do you have a damp, dark, and cold basement that you’re looking to make an integral part of your home?  Has the basement in your home turned into a giant dumping zone for storage?  Let Mike’s Services renovate your basement and add valuable square footage to your home!</p>
                        <p style={paragraph}>Turn any basement into comfortable space (man cave, playroom, finished storage) that’s just right for your home.  No two project are the same which allows for endless opportunities and renovation suggestions from our team.  We can work within any budget to make your project come to life.  The best part of our day is seeing the smile on a clients face when they see their finished basement for the first time.  Get excited, give us a <a href="tel:734-591-0825">call</a>, and let's start planning today!</p>
                    </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8} style={{ minHeight: "40vh" }}>
                    <Parallax
                        blur={0}
                        bgImage={BasementPhoto}
                        bgImageAlt="basement banner"
                        strength={300}
                    >
                        <div style={{ height: "40vh", alignItems: "center" }} />
                    </Parallax>

                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16} style={{ borderTop: `1em solid ${Constants.Maroon}` }}>
                    <Parallax
                        blur={0}
                        bgImage={DarkBrick}
                        bgImageAlt="brick banner"
                        strength={300}
                    >
                        <div style={{ ...Constants.Overlay, minHeight: "40vh", textAlign: "center" }}>
                            <br />
                            <h1>Get your project off the ground!</h1>
                            <Grid>
                                <Grid.Row only="computer">
                                    <Grid.Column width={16}>
                                        <div style={{ display: "flex", justifyContent: "center", textAlign: "left" }}>
                                            <div style={{ lineHeight: "40px", display: "flex", alignItems: "center" }}>
                                                <ul>
                                                    <li>Spacious layouts</li>
                                                    <li>Storage closets/units</li>
                                                    <li>Insulate and drywall exterior walls</li>
                                                    <li>Finished ceilings</li>
                                                </ul>
                                            </div>
                                            <div style={{ lineHeight: "40px", display: "flex", alignItems: "center" }}>
                                                <ul>
                                                    <li>Updated electrical</li>
                                                    <li>Egress windows</li>
                                                    <li>Bedrooms</li>
                                                    <li>Laundry rooms</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row only="mobile tablet">
                                    <Grid.Column width={16}>
                                        <div style={{ lineHeight: "40px", display: "flex", alignItems: "center" }}>
                                            <ul style={{listStyle: "none"}}>
                                                <li>Spacious layouts</li>
                                                <li>Storage closets/units</li>
                                                <li>Insulate and drywall exterior walls</li>
                                                <li>Finished ceilings</li>
                                                <li>Updated electrical</li>
                                                <li>Egress windows</li>
                                                <li>Bedrooms</li>
                                                <li>Laundry rooms</li>
                                            </ul>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    </Parallax>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};