import React from 'react';
import { Grid , Icon } from 'semantic-ui-react';
import * as Constants from '../constants';
import { Parallax } from 'react-parallax';

import WhiteRoom from '../img/white-banner.jpg';
import WhiteBathroom from '../img/white-bathroom-banner.jpg';
import TwinMirrors from '../img/twin-mirrors-banner.jpg';

export default function Home() {
    const headerStyle = {
        color: Constants.Maroon,
        fontFamily: "'Playfair Display', serif"
    };

    const paragraphStyle = {
        color: Constants.Maroon,
        fontFamily: "'Noto Serif', serif",
        fontSize: "1.2rem"
    };

    return (
        <React.Fragment>
            <Grid style={{backgroundColor: Constants.OffWhite}}>
                <Grid.Row style={{ width: "100%", borderBottom: `1em solid ${Constants.Maroon}` }}>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <Parallax
                            blur={0}
                            bgImage={WhiteRoom}
                            bgImageAlt="white kitchen banner"
                            strength={300}
                        >
                            <div style={{ height: "40vh", alignItems: "center" }} />
                        </Parallax>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16} style={{...Constants.FlexCenter, minHeight: "40vh"}}>
                        <div style={{width: "90%"}}>
                            <h1 style={{ ...headerStyle, paddingBottom: ".5em" }}>35 YEARS OF SERVICE, CRAFTSMANSHIP AND VALUE.</h1>
                            <p style={{ ...paragraphStyle, paddingBottom: ".5em" }}>
                                Whether it's a newly finished basement, freshly remodeled bathroom, or a sleek modernized kitchen, Mike's Services does it all.  After thirty five years of servicing to the Livonia, Farmington, Novi, Plymouth, Canton, South Lyon, Farmington Hills &amp; Milford communities, few can match our expertise.</p>
                            <p style={paragraphStyle}>Call&nbsp;<a href="tel:734-591-0825">734-591-0825</a>&nbsp;today for an estimate!</p>
                            <p style={paragraphStyle}>Email us at&nbsp;<a href="mailto:mikeserv7@yahoo.com">mikeserv7@yahoo.com</a>&nbsp;anytime for details.</p>                                                        
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ borderBottom: `1em solid ${Constants.Maroon}` }}>
                    <Grid.Column width={16}>
                        <Parallax
                            blur={0}
                            bgImage={TwinMirrors}
                            bgImageAlt="dark twin mirrors bathroom"
                            strength={300}
                        >      
                                <Grid style={{...Constants.Overlay, minHeight: "40vh", marginLeft: "2em", marginRight: "2em" }}>
                                    <Grid.Row style={{ ...Constants.FlexCenter}}>
                                        <Grid.Column width={16} style={{textAlign: "center"}}>
                                            <p>I recently had a client do a full bathroom remodel using Mike. The craftsmanship is above and beyond. Mike is prompt and detailed. You will not be disappointed! <br />- Tim B</p>
                                            <p>Very professional, did an amazing job on our remodel.  Highly recommend!.  <br />- Joy B</p>
                                            <p>Superior craftsmanship for modern home remodeling. Completed a full bathroom and the new kitchen is almost finished.  <br />- Connor L</p>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>      
                        </Parallax>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ width: "100%", borderBottom: `1em solid ${Constants.Maroon}` }}>
                    <Grid.Column computer={8} tablet={16} mobile={16} style={{...Constants.FlexCenter, minHeight: "40vh"}}>
                    <div style={{width: "85%"}}>
                            <h1 style={{ ...headerStyle, paddingBottom: ".5em" }}>NEED HELP?  NO PROBLEM.</h1>
                            <p style={{ ...paragraphStyle, paddingBottom: ".5em" }}>
                                Don't know exactly what you want?  We can help!  Just <a href="tel:734-591-0825">call</a> us for an estimate.  
                            </p>
                            <p style={{ ...paragraphStyle, paddingBottom: ".5em" }}>
                                Our expert team will go over your project, budget, and help with any desicions. Before you know it you'll be living in the comfort and style you desire. 
                            </p>
                            <p style={{ ...paragraphStyle, paddingBottom: ".5em" }}>
                                We're fully licensed, insured and ready to start on that dream renovation all while increasing the value of your home!
                            </p>
                        </div>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <Parallax
                            blur={0}
                            bgImage={WhiteBathroom}
                            bgImageAlt="white kitchen banner"
                            strength={300}
                        >
                            <div style={{ height: "40vh", alignItems: "center" }} />
                        </Parallax>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
}