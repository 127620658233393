import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Parallax } from 'react-parallax';

import * as Constants from '../constants';
import GreenBathroom from '../img/bathroom-green.jpg';
import DarkBathroom from '../img/dark-bathroom.jpg';

export default function Bathroom() {
    const paragraph = {
        ...Constants.Paragraph,
        fontSize: "1.2rem"
    };

    const header = {
        ...Constants.Header
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8} style={{ minHeight: "40vh" }}>
                    <Parallax
                        blur={0}
                        bgImage={GreenBathroom}
                        bgImageAlt="kitchen banner"
                        strength={300}
                    >
                        <div style={{ height: "40vh", alignItems: "center" }} />
                    </Parallax>

                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8} style={{ ...Constants.FlexCenter, minHeight: "40vh" }}>
                    <div style={{ width: "90%" }}>
                        <h1 style={header}>REVAMP THE DATED BATHROOM</h1>
                        <p style={paragraph}>Looking to improve your current bathroom? You’ve come to the right place! Mike’s Services specializes in bathroom remodels of all shapes and sizes. Looking to modernize countertops and and an extra sink? No problem! Want to add built in storage and update the shower? We’ve got you covered! Update from a drab and dated master bath to a spa like dream? Let’s get started!</p>
                        <p style={paragraph}>Our team has done it all, and we’re here to help you achieve you dream bathroom without the hassle and headaches of DIY projects. Let us make all the trips to the hardware store - Call&nbsp;<a href="tel:734-591-0825">734-591-0825</a> today! We’ll set up an estimate appointment to help identify your must-haves and the best way to stay within budget.</p>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16} style={{ borderTop: `1em solid ${Constants.Maroon}` }}>
                    <Parallax
                        blur={0}
                        bgImage={DarkBathroom}
                        bgImageAlt="kitchen banner"
                        strength={300}
                    >
                        <div style={{ ...Constants.Overlay, minHeight: "40vh", textAlign: "center" }}>
                            <br />
                            <h1>Get your project off the ground!</h1>
                            <Grid>
                                <Grid.Row only="computer">
                                    <Grid.Column width={16}>
                                        <div style={{ display: "flex", justifyContent: "center", textAlign: "left" }}>
                                            <div style={{ lineHeight: "40px", display: "flex", alignItems: "center" }}>
                                                <ul>
                                                    <li>Vanity countertops and sinks</li>
                                                    <li>Storage improvements</li>
                                                    <li>Updated flooring</li>
                                                    <li>Tub/Shower replacement</li>
                                                </ul>
                                            </div>
                                            <div style={{ lineHeight: "40px", display: "flex", alignItems: "center" }}>
                                                <ul>
                                                    <li>New tiling</li>
                                                    <li>Lighting fixtures</li>
                                                    <li>Plumbing updates</li>
                                                    <li>Layout updates</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row only="mobile tablet">
                                    <Grid.Column width={16}>
                                        <div style={{ lineHeight: "40px", display: "flex", alignItems: "center" }}>
                                            <ul style={{ listStyle: "none" }}>
                                                <li>Vanity countertops and sinks</li>
                                                <li>Storage improvements</li>
                                                <li>Updated flooring</li>
                                                <li>Tub/Shower replacement</li>
                                                <li>New tiling</li>
                                                <li>Lighting fixtures</li>
                                                <li>Plumbing updates</li>
                                                <li>Layout updates</li>
                                            </ul>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    </Parallax>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};