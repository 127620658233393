import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Parallax } from 'react-parallax';

import * as Constants from '../constants';
import ModernKitchen from '../img/modern-kitchen.jpg';
import DarkKitchen from '../img/dark-kitchen.jpg';

export default function Kitchen() {
    const paragraph = {
        ...Constants.Paragraph,
        fontSize: "1.2rem"
    };

    const header = {
        ...Constants.Header
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8} style={{ ...Constants.FlexCenter, minHeight: "40vh" }}>
                    <div style={{ width: "90%" }}>
                        <h1 style={header}>REIMAGINE YOUR KITCHEN</h1>
                        <p style={paragraph}>In our 35 years of experience, we’ve quoted kitchen remodels ranging from minor cosmetic changes to complete “gut” job renovations. No matter your project size we can help bring one of the most popular spots in your home to it’s full potential. A remolded kitchen adds value to your home while enhancing living space and creating the perfect space for gatherings, family fun night, dinner parties and so much more.</p>
                        <p style={paragraph}>Call us at&nbsp;<a href="tel:734-591-0825">734-591-0825</a> today! Together we can design a kitchen you see in magazines and that you never dreamed could be in your home.</p>
                    </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8} style={{ minHeight: "40vh" }}>
                    <Parallax
                        blur={0}
                        bgImage={ModernKitchen}
                        bgImageAlt="kitchen banner"
                        strength={300}
                    >
                        <div style={{ height: "40vh", alignItems: "center" }} />
                    </Parallax>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16} style={{ borderTop: `1em solid ${Constants.Maroon}` }}>
                    <Parallax
                        blur={0}
                        bgImage={DarkKitchen}
                        bgImageAlt="kitchen banner"
                        strength={300}
                    >
                        <div style={{ ...Constants.Overlay, minHeight: "40vh", textAlign: "center" }}>
                            <br />
                            <h1>Get your project off the ground!</h1>
                            <Grid>
                                <Grid.Row only="computer">
                                    <Grid.Column width={16}>
                                        <div style={{ display: "flex", justifyContent: "center", textAlign: "left" }}>
                                            <div style={{ lineHeight: "40px", display: "flex", alignItems: "center" }}>
                                                <ul>
                                                    <li>Update wiring, lighting and fixtures</li>
                                                    <li>Custom backsplash</li>
                                                    <li>Update plumbing and faucets</li>
                                                    <li>Flooring/Tile</li>
                                                    <li>Fix inefficient layouts</li>
                                                </ul>
                                            </div>
                                            <div style={{ lineHeight: "40px", display: "flex", alignItems: "center" }}>
                                                <ul>
                                                    <li>Countertop installation</li>
                                                    <li>Update/replace cabinets</li>
                                                    <li>Under cabinet lighting</li>
                                                    <li>Replace cabinet hardware</li>
                                                    <li>Create a kitchen island</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row only="mobile tablet">
                                    <Grid.Column width={16}>
                                        <div style={{ lineHeight: "40px", display: "flex", alignItems: "center" }}>
                                            <ul style={{ listStyle: "none" }}>
                                                <li>Update wiring, lighting and fixtures</li>
                                                <li>Custom backsplash</li>
                                                <li>Update plumbing and faucets</li>
                                                <li>Flooring/Tile</li>
                                                <li>Fix inefficient layouts</li>
                                                <li>Countertop installation</li>
                                                <li>Update/replace cabinets</li>
                                                <li>Under cabinet lighting</li>
                                                <li>Replace cabinet hardware</li>
                                                <li>Create a kitchen island</li>
                                            </ul>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    </Parallax>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};