import React, { useState, useCallback } from 'react';

import { Grid } from 'semantic-ui-react';
import PhotoGallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import * as Constants from '../constants';

export default function Gallery(props) {
    const photos = [
        {
            src: "/img/1.jpg",
            width: 4,
            height: 4,
            description: "Custom made shower with stone base"
        },
        {
            src: "/img/2.jpg",
            width: 4,
            height: 4,
            description: "Kitchen remodel with new oak cabinets, backsplash, tile floor and quartz counters"
        },
        {
            src: "/img/3.jpg",
            width: 4,
            height: 4,
            description: "Kitchen remodel with new oak cabinets, backsplash, tile floor and quartz counters"
        },
        {
            src: "/img/4.jpg",
            width: 5,
            height: 3,
            description: "Kitchen remodel with new oak cabinets, backsplash, tile floor and quartz counters"
        },
        {
            src: "/img/5.jpg",
            width: 4,
            height: 4,
            description: "Reconfigured wall and relocation of appliances"
        },
        {
            src: "/img/6.jpg",
            width: 4,
            height: 4,
            description: "Tiled backsplash and under cabinet lighting"
        },
        {
            src: "/img/7.jpg",
            width: 5,
            height: 3,
            description: "Porcelain Parquet-like floor in shower stall"
        },
        {
            src: "/img/8.jpg",
            width: 5,
            height: 3,
            description: "Glass enclosed shower and deep soaking tub"
        },
        {
            src: "/img/9.jpg",
            width: 5,
            height: 3,
            description: "Farm sink with tiled backsplash and chiseled edge granite counter top"
        },
        {
            src: "/img/10.jpg",
            width: 5,
            height: 4,
            description: "Shower stall with seat and custom stone base"
        },
        {
            src: "/img/11.jpg",
            width: 4,
            height: 4,
            description: "Accent tiled inlay and glass door"
        },
        {
            src: "/img/12.jpg",
            width: 4,
            height: 4,
            description: "Finished basement with wall framing with drywall and drop ceiling"
        },
        {
            src: "/img/13.jpg",
            width: 4,
            height: 4,
            description: "Framing and drywall around ducts and support poles"
        },
        {
            src: "/img/14.jpg",
            width: 4,
            height: 4,
            description: "Bathroom installation in basement with 36� shower stall, linen closet, vanity and toilet"
        },
        {
            src: "/img/15.jpg",
            width: 4,
            height: 4,
            description: "Bathroom remodel with new vanity, shower stall and quartz counter"
        },
        {
            src: "/img/16.jpg",
            width: 4,
            height: 4,
            description: "Custom blue glass tile inlay in shower stall"
        },
        {
            src: "/img/17.jpg",
            width: 4,
            height: 4,
            description: "Bathroom remodel with new vanity, shower stall and quartz counter"
        },
        {
            src: "/img/18.jpg",
            width: 4,
            height: 4,
            description: "Kitchen reconstruction including cabinetry, island, coffee bar, and new appliances"
        },
        {
            src: "/img/19.jpg",
            width: 4,
            height: 4,
            description: "Open concept kitchen, load-bearing wall removed"
        },
        {
            src: "/img/20.jpg",
            width: 4,
            height: 4,
            description: "Open concept kitchen, load-bearing wall removed"
        },
        {
            src: "/img/21.jpg",
            width: 4,
            height: 4,
            description: "Open concept kitchen, load-bearing wall removed"
        },
        {
            src: "/img/22.jpg",
            width: 4,
            height: 4,
            description: "Pot filler plumbing over custom range"
        },
        {
            src: "/img/23.jpg",
            width: 4,
            height: 4,
            description: "Open concept kitchen, load-bearing wall removed"
        },
        {
            src: "/img/24.jpg",
            width: 4,
            height: 4,
            description: "Bathtub conversion to shower stall with inlay shelves, marble floor and rain shower head"
        }
    ];

    const init = {
        index: 0,
        open: false
    };

    const [current, setCurrent] = useState(init);

    const openLightBox = useCallback((event, { photo, index }) => {
        setCurrent({ index, open: true });
    }, []);

    const closeLightBox = () => {
        setCurrent({ index: 0, open: false });
    };

    return (
        <React.Fragment>
            <Grid stackable padded>
                <Grid.Row style={{marginTop: "1em", marginBottom: "1em"}}>
                    <Grid.Column width={16} textAlign="center">
                        <h1 style={Constants.Header}>Gallery - &nbsp;<em>Click to Enlarge</em></h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column only="computer" width={1} textAlign="center"></Grid.Column>
                    <Grid.Column only="computer" width={14} textAlign="center">
                        <PhotoGallery photos={photos} onClick={openLightBox} />
                    </Grid.Column>
                    <Grid.Column only="computer" width={1} textAlign="center"></Grid.Column>
                    <Grid.Column only="tablet mobile" width={16} textAlign="center">
                        <PhotoGallery photos={photos} onClick={openLightBox} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <ModalGateway>
                {current.open ? (
                    <Modal onClose={closeLightBox} >
                        <Carousel
                            currentIndex={current.index}
                            views={photos.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.description
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </React.Fragment>
    )
}