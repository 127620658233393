export const Home = "home";
export const Kitchen = "kitchen";
export const Bathroom = "bathroom";
export const Gallery = "gallery";
export const About = "about";
export const Contact = "contact";
export const Basement = "basement";

export const MobileBreakpoint = 1024;

export const Maroon = "rgba(86,31,34,1)";
export const Teal = "rgba(0,128,129,1)";
export const OffWhite = "#eee";
export const FlexCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

export const Header = {
    color: Maroon,
    fontFamily: "'Playfair Display', serif"
};

export const Paragraph = {
    color: Maroon,
    fontFamily: "'Noto Serif', serif"
};

export const Overlay = {
    background: "linear-gradient(0deg, rgba(85, 85, 85, 0.4), rgba(80, 80, 80, 0.4))",
    fontFamily: "'Playfair Display', serif",
    color: "white", 
    textShadow: "2px 1px black", 
    fontSize: "1.2rem",
    letterSpacing: "1.2px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
};