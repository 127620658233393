import React, { useState } from 'react';
import { Menu, Responsive, Sidebar, Image, Grid } from 'semantic-ui-react';
import * as Constants from '../constants';
import * as Actions from '../Stores/actions';

import { useDispatch, useSelector } from 'react-redux';
import Logo from '../img/full-logo.png';
import Burger from '../img/burger.png';

export default function Header() {
    const dispatch = useDispatch();

    const setItem = section => event => {
        dispatch({ type: Actions.UPDATE_SELECTED_SECTION, section: section });
        handleSideBar(false);
    };

    const currentlySelected = useSelector(state => state.global.currentlySelectedSection);
    const [showSidebar, handleSideBar] = useState(false);

    const isCurrentlySelected = (sectionName) => {
        return sectionName === currentlySelected;
    };

    const selectedStyle = {
        backgroundColor: Constants.Teal,
        color: Constants.OffWhite,
        fontFamily: "'Noto Serif', serif"
    };

    const notSelectedStyle = {
        backgroundColor: Constants.Maroon,
        color: Constants.OffWhite,
        fontFamily: "'Noto Serif', serif"
    };

    const headerStyle = {
        color: Constants.Maroon,
        fontFamily: "'Playfair Display', serif"
    };

    const menuItems = (
        <React.Fragment>
            <Menu.Item
                style={isCurrentlySelected(Constants.Home) ? selectedStyle : notSelectedStyle}
                onClick={setItem(Constants.Home)}
            >Home</Menu.Item>
            <Menu.Item
                style={isCurrentlySelected(Constants.Kitchen) ? selectedStyle : notSelectedStyle}
                onClick={setItem(Constants.Kitchen)}
            >Kitchens</Menu.Item>
            <Menu.Item
                style={isCurrentlySelected(Constants.Bathroom) ? selectedStyle : notSelectedStyle}
                onClick={setItem(Constants.Bathroom)}
            >Bathrooms</Menu.Item>
            <Menu.Item
                style={isCurrentlySelected(Constants.Basement) ? selectedStyle : notSelectedStyle}
                onClick={setItem(Constants.Basement)}
            >Basements</Menu.Item>
            <Menu.Item
                style={isCurrentlySelected(Constants.About) ? selectedStyle : notSelectedStyle}
                onClick={setItem(Constants.About)}
            >About Us</Menu.Item>
            <Menu.Item
                style={isCurrentlySelected(Constants.Gallery) ? selectedStyle : notSelectedStyle}
                onClick={setItem(Constants.Gallery)}
            >Gallery</Menu.Item>
        </React.Fragment>
    )

    return (
        <div style={{ backgroundColor: Constants.OffWhite, width: "100%", borderBottom: `1em solid ${Constants.Maroon}` }}>
            {/* Begin Desktop */}
            <Responsive minWidth={Constants.MobileBreakpoint}>
                <Grid>
                    <Grid.Row style={{ height: "62px" }}>
                        <Grid.Column width={16}>
                            <Menu style={{ ...Constants.FlexCenter, backgroundColor: "rgba(86,31,34,1)"}}>
                                {menuItems}
                            </Menu>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ ...Constants.FlexCenter }}>                        
                        <Grid.Column width={12} style={Constants.FlexCenter}>                            
                            <Image src={Logo} style={{height: "55px"}} />                         
                        </Grid.Column>
                        <Grid.Column width={12} style={Constants.FlexCenter}>
                            <h1 style={{ ...headerStyle, fontSize: "42px", lineHeight: "1em", textAlign: "center" }}>MIKE'S SERVICES</h1>    
                            <br/>                      
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Responsive>

            {/* Begin Mobile */}
            <Responsive maxWidth={Constants.MobileBreakpoint - 1}>
                <div style={{ height: "60px", backgroundColor: Constants.Maroon, display: "flex", alignItems: "center" }}>
                    <Image size="mini"
                        src={Burger}
                        onClick={() => { handleSideBar(true) }}
                        style={{ position: "absolute", left: "30px", cursor: "pointer", zIndex: "99" }}
                        hidden={showSidebar}
                    />
                </div>
                <Grid>
                    <Grid.Row style={Constants.FlexCenter}>
                        <Grid.Column width={12} style={Constants.FlexCenter}>
                            <Image src={Logo} style={{height: "55px"}} />   
                        </Grid.Column>
                        <Grid.Column width={12} style={Constants.FlexCenter}>
                            <h1 style={{ ...headerStyle, fontSize: "28px", textAlign: "center" }}>MIKE'S SERVICES</h1>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted
                    onHide={() => { handleSideBar(false) }}
                    vertical
                    visible={showSidebar}
                    width='thin'
                    style={{ zIndex: "100" }}
                >
                    {menuItems}
                </Sidebar>
            </Responsive>
        </div>
    )
}
