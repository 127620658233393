import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Parallax } from 'react-parallax';

import * as Constants from '../constants';
import BrownChairs from '../img/brown-chairs.jpg';
import KitchenCorner from '../img/kitchen-corner.jpg';

export default function About() {
    const paragraph = {
        ...Constants.Paragraph,
        fontSize: "1.2rem"
    };

    const header = {
        ...Constants.Header
    };

    return (
        <Grid>
        <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8} style={{ minHeight: "40vh" }}>
                <Parallax
                    blur={0}
                    bgImage={BrownChairs}
                    bgImageAlt="kitchen banner"
                    strength={300}
                >
                    <div style={{ height: "40vh", alignItems: "center" }} />
                </Parallax>

            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8} style={{ ...Constants.FlexCenter, minHeight: "40vh" }}>
                <div style={{ width: "90%" }}>
                    <h1 style={header}>ABOUT US</h1>
                    <p style={paragraph}>Based out of Livonia, Mike's Services has been serving the Metro Detroit Area for over thirty-five years. Owner and operator Mike Manoogian personally takes part in every project; his versatility and dependability has resulted in top notch customer satisfaction over the years.  By taking the time to carefully go over your needs and budget, Mike makes your vision a reality.</p>
                    <p style={paragraph}>More questions about our business?  Ready to get going on your project?  Call&nbsp;<a href="tel:734-591-0825">734-591-0825</a> and lets get started.</p>
                </div>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={16} style={{ borderTop: `1em solid ${Constants.Maroon}` }}>
                <Parallax
                    blur={0}
                    bgImage={KitchenCorner}
                    bgImageAlt="kitchen banner"
                    strength={300}
                >
                    <div style={{ ...Constants.Overlay, minHeight: "40vh", textAlign: "center" }}>
                        <br />
                        <h1>We pride ourselves on your satisfaction!</h1>
                        <Grid>
                            <Grid.Row only="computer">
                                <Grid.Column width={16}>
                                    <div style={{ display: "flex", justifyContent: "center", textAlign: "left" }}>
                                        <div style={{ lineHeight: "40px", display: "flex", alignItems: "center" }}>
                                            <ul>
                                                <li>Professional</li>
                                                <li>Fully licensed</li>
                                                <li>Experienced</li>
                                            </ul>
                                        </div>
                                        <div style={{ lineHeight: "40px", display: "flex", alignItems: "center" }}>
                                            <ul>
                                                <li>Proven</li>
                                                <li>Reliable</li>
                                                <li>Headache-Free</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row only="mobile tablet">
                                    <Grid.Column width={16}>
                                        <div style={{ lineHeight: "40px" }}>
                                            <ul style={{listStyle: "none", paddingInlineStart: "0" }}>
                                                <li>Professional</li>
                                                <li>Fully licensed</li>
                                                <li>Experienced</li>
                                                <li>Proven</li>
                                                <li>Reliable</li>
                                                <li>Headache-Free</li>
                                        </ul>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Parallax>
            </Grid.Column>
        </Grid.Row>
    </Grid>
    );
};